<template>
  <aside
    tag="aside"
    class="sidebar"
  >
    <nav :aria-label="$t('component.sidebar.aria.label')">
      <SHeading
        level="4"
        tag="h1"
      >
        {{ $t('component.sidebar.heading') }}
      </SHeading>

      <ul class="sidebar__links">
        <li
          v-for="({ label, path }) in links"
          :key="path"
        >
          <SLink :to="localePath(path)">
            {{ $t(label) }}
          </SLink>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { legionellaLinks } from '~/assets/routes'

export default {
  name: 'TheSidebar',

  data () {
    return {
      links: legionellaLinks,
    }
  },
}
</script>

<style lang="scss">
.sidebar {
  @include after-breakpoint('md') {
    margin-left: spacing();
  }

  position: sticky;
  top: spacing('double');

  &__links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
</style>
