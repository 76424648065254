export default function ({ app, route, redirect }) {
  // TODO: fix double refresh
  const hasCode = /\/code(\d{4})/.exec(route.path.substr(0, 12))

  if (hasCode !== null) {
    return redirect(`${app.localePath('legionella-troubleshooting')}#${hasCode[1]}`)
  }

  // catches links from legionella GUI
  if (route.query && route.query.token) {
    return redirect(`${app.localePath('legionella-troubleshooting')}${route.hash}`)
  }
}
