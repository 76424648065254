<template>
  <SButtonGroup
    v-if="$i18n.locales.length > 1"
    is-grouped
  >
    <NuxtLink
      v-for="(locale, index) in $i18n.locales"
      :key="index"
      :exact="true"
      :to="switchLocalePath(locale.code)"
      :class="`button--color-white${locale.code === $i18n.locale ? '': '-outlined'}`"
      class="button button--locale-selector"
      @click.native="applySwitch(locale.code)"
    >
      {{ locale.code | strToUpperCase }}
    </NuxtLink>
  </SButtonGroup>
</template>

<script>
export default {
  filters: {
    strToUpperCase (str) {
      return str.toUpperCase()
    },
  },

  methods: {
    applySwitch (code) {
      this.$store.dispatch('i18n/setLocale', code)
    },
  },
}
</script>

<style lang="scss">
.button--locale-selector {
  &.button--color-white {
    color: color('night');

    &-outlined {
      color: color('white');
    }
  }
}
</style>
