// ./plugins/contentful.js
import orderBy from 'lodash.orderby'

const domain = process.env.CTF_CDA_ACCESS_TOKEN ? 'cdn' : 'preview'
const CTF_URL = new URL(`https://${domain}.contentful.com`)

export default ({ app, env, isDev, store }, inject) => {
  inject('ctf', {
    async getEntries ({ locale = app.i18n.locale + '-CA', contentType, order }) {
      try {
        const reqUrl = new URL(`/spaces/${env.CTF_SPACE_ID}/environments/master/entries`, CTF_URL)

        reqUrl.search = new URLSearchParams({
          /* eslint-disable camelcase */
          access_token: process.env.CTF_CDA_ACCESS_TOKEN || process.env.CTF_CPA_ACCESS_TOKEN,
          content_type: contentType,
          /* eslint-enable camelcase */
          locale,
        })

        // using FetchAPI instead of Contentful sdk for better interaction with service worker
        const response = await fetch(reqUrl)
        const { items } = await response.json()

        return {
          posts: orderBy(items, order ? item => item.fields[order] : undefined),
          total: items.length,
          eTag: response.headers.get('ETag'),
        }
      } catch (err) {
        if (isDev) console.error(err)
      }
    },

    async loadContent ({ lang, contentType, order }) {
      return store.dispatch('content/fetchContent', { lang, contentType, order })
    },
  })
}
