<template>
  <footer class="footer">
    <SContainer>
      <template v-for="(category, key) in { navigationLinks, legalLinks }">
        <SContainerRow
          :key="key"
          justify-until-md="center"
          justify-after-md="end"
          class="footer__row"
        >
          <SContainerColumn
            narrow
            style="padding:0;"
          >
            <ul class="footer__links no-bullets">
              <li
                v-for="(route, routeKey) in category"
                :key="routeKey"
                class="footer__item column column--md column--narrow text--align-center"
              >
                <SLink
                  is-plain
                  :to="localePath(route.path)"
                >
                  {{ $t(route.label) }}
                </SLink>
              </li>
            </ul>
          </SContainerColumn>
        </SContainerRow>
      </template>

      <SContainerRow justify="end">
        <SContainerColumn
          narrow-after-md
          class="caption text--align-center"
        >
          {{ copyright }}
        </SContainerColumn>
      </SContainerRow>
    </SContainer>
  </footer>
</template>

<script>
import { navigationLinks, legalLinks } from '~/assets/routes'

export default {
  data () {
    return {
      navigationLinks,
      legalLinks,
    }
  },

  computed: {
    copyright () {
      return `${this.$t('component.footer.copyright')} ${new Date().getFullYear()}`
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: color('ice');
  padding: spacing('triple');

  &__item {
    display: inline-block;
    margin: spacing('half') 0;
    padding: 0 spacing();

    .link--plain {
      color: color('blue');
    }

    @include after-breakpoint('md') {
      &:not(:first-child) {
        border-left: border();
      }
    }
  }

  &__links {
    display: inline-block;
    margin: spacing('none') !important;
  }
}
</style>
