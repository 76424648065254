import Vue from 'vue'

/**
 * Disables transitions between routes with the same base name
 * This prevents text changing, and then the fade transition firing when changing translations
 * e.g.: /faq (faq__en) -> /fr/faq (faq__fr) have the same base route name (faq)
 */
Vue.mixin({
  transition (to, from) {
    const hasToRoute = to && to.name
    const hasFromRoute = from && from.name
    if (!hasToRoute || !hasFromRoute) return

    const getBaseName = route => route.name.split('__')[0]

    if (getBaseName(to) === getBaseName(from)) return 'none'
  },
})
