/**
 * @typedef {Object} NavigationLink
 * @property {string} path The non-localized path to the page
 * @property {string} label The i18n key to display as the link text
 */

/** @type {NavigationLink[]} */
export const navigationLinks = [
  {
    path: 'legionella',
    label: 'component.navigation.legionella',
  },
  {
    path: 'contact',
    label: 'component.navigation.contact',
  },
]

/** @type {NavigationLink[]} */
export const legionellaLinks = [
  {
    path: 'legionella-faq',
    label: 'component.navigation.faq',
  },
  {
    path: 'legionella-troubleshooting',
    label: 'component.navigation.troubleshooting',
  },
  {
    path: 'legionella-workflow',
    label: 'component.navigation.workflow',
  },
  {
    path: 'legionella-downloads',
    label: 'component.navigation.downloads',
  },

]

/** @type {NavigationLink[]} */
export const legalLinks = [
  {
    path: 'accessibility',
    label: 'component.navigation.accessibility',
  },
  {
    path: 'privacy-policy',
    label: 'component.navigation.privacyPolicy',
  },
  {
    path: 'terms-of-use',
    label: 'component.navigation.termsOfUse',
  },
]

export const routes = [...navigationLinks, ...legalLinks]
