<template>
  <BaseLayout>
    <Nuxt />
  </BaseLayout>
</template>

<script>
import BaseLayout from '~/components/BaseLayout.vue'

export default {
  components: {
    BaseLayout,
  },
}
</script>
