<template>
  <SContainerRow justify="center">
    <SContainerColumn
      narrow
      class="text--align-center"
    >
      <SHeading
        v-if="statusCode"
        level="1"
      >
        {{ message }}
      </SHeading>

      <SHeading
        v-else
        level="1"
      >
        {{ $t('global.error.noCode') }}
      </SHeading>

      <EmptyContent />
    </SContainerColumn>
  </SContainerRow>
</template>

<script>
import EmptyContent from '~/components/EmptyContent.vue'

export default {
  components: {
    EmptyContent,
  },

  props: {
    error: {
      type: [Object, Error],
      default: () => ({}),
    },
  },

  data () {
    return {
      statusCode: this.error.statusCode,
      siteUrl: process.env.BASE_URL,
    }
  },

  computed: {
    message () {
      if (this.statusCode >= 300 && this.statusCode < 400) return this.$t('global.error.3xx')
      if (this.statusCode === 404) return this.$t('global.error.404')
      return this.$t('global.error.noCode')
    },
  },
}
</script>
