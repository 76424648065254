<template>
  <TheHeaderNavigation />
</template>

<script>
import TheHeaderNavigation from '~/components/TheHeaderNavigation'

export default {
  components: {
    TheHeaderNavigation,
  },
}
</script>
