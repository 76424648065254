import Vue from 'vue'
// polyfill fetch for ie
import 'whatwg-fetch'

export const state = () => ({
  files: {},
})

export const mutations = {
  updateFiles (state, { files, category }) {
    Vue.set(state.files, category, files)
  },
}

export const actions = {
  async fetchUpdates ({ state, commit }, { path }) {
    if (!path) throw new Error('`path` is required')

    const category = path.split('/').shift()
    const hasFiles = state.files[category] && Object.values(state.files[category]).length

    if (hasFiles) return Promise.resolve()

    let files = []

    try {
      files = await this.$s3.getUpdates(path)
    } catch (err) {
      console.error(err)
    } finally {
      commit('updateFiles', { files, category })
    }
  },
}
