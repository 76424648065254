export default (ctx, inject) => {
  inject('s3', {
    async getDocuments (path) {
      return getFromS3('get-documents', path)
    },

    async getUpdates (path) {
      return getFromS3('get-updates', path)
    },
  })

  const functionsPath = '/.netlify/functions'

  async function getFromS3 (endpoint, awsPath) {
    let reqPath = `${functionsPath}/${endpoint}`

    if (awsPath) {
      const params = new URLSearchParams({ path: awsPath })
      reqPath += `?${params}`
    }

    const objects = await fetch(reqPath)

    return objects.json()
  }
}
