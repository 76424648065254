<template>
  <div
    :class="computedClass"
    class="logo"
  >
    <div class="sr-only">
      Spartan Bioscience Inc.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logoColor: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedClass () {
      return this.logoColor ? `logo--${this.logoColor}` : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  background: url('~assets/images/logo.svg') center center / contain no-repeat;
  margin: 0 auto;
  max-width: 10rem;
  min-height: 2rem;
  min-width: 110px;

  &--white {
    background: url('~assets/images/logo_white.svg') center center / contain no-repeat;
  }
}
</style>
