import createPersistedState from 'vuex-persistedstate'

export default function ({ isDev, store }) {
  createPersistedState({
    key: 'spartan-portal',
    // caches available downloads.
    paths: ['downloads'],
    storage: window.sessionStorage,
  })(store)
}
