import Vue from 'vue'
import { extend, localize, setInteractionMode, ValidationProvider } from 'vee-validate'
/* eslint-disable camelcase */
import { email, required, required_if } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

setInteractionMode('debounced', ({ errors }) => {
  if (errors.length) {
    return {
      on: ['input', 'change'],
    }
  }

  return {
    on: ['input', 'blur'],
    debounce: 300,
  }
})
extend('required', required)
extend('required_if', required_if)
extend('email', email)
extend('url', {
  validate: value => value.match(/(https?:\/\/)?.+\..+/),
  message: field => `The ${field} must be a URL`,
})
localize({
  en,
})

/* eslint-disable vue/match-component-file-name */
Vue.component('ValidationProvider', ValidationProvider)
