<template>
  <main>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="text--align-center"
      v-html="$md.render($t('component.emptyContent.empty'))"
    />
    <!-- empty-state.svg -->
    <!-- eslint-disable max-len -->
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 465.5 465.5"
      style="enable-background:new 0 0 465.5 465.5;"
      xml:space="preserve"
    >
      <g>
        <circle
          class="circle"
          cx="232.8"
          cy="232.8"
          r="224.3"
        />
      </g>
      <g class="paper">
        <g>
          <path
            class="st1"
            d="M397,453.5H68.5c-2.6,0-4.7-2.1-4.7-4.7v-432c0-2.6,2.1-4.7,4.7-4.7H397c2.6,0,4.7,2.1,4.7,4.7v432
          C401.8,451.4,399.7,453.5,397,453.5z M73.2,444.1h319.1V21.5H73.2V444.1z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M196.5,72h-83c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7h83c2.6,0,4.7,2.1,4.7,4.7
          C201.2,69.9,199.1,72,196.5,72z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M334.9,128H113.4c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7h221.4c2.6,0,4.7,2.1,4.7,4.7
          C339.6,125.9,337.5,128,334.9,128z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M284.2,184.1H113.4c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7h170.7c2.6,0,4.7,2.1,4.7,4.7
          C288.9,182,286.8,184.1,284.2,184.1z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M314.2,240.1H113.4c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7h200.7c2.6,0,4.7,2.1,4.7,4.7
          S316.8,240.1,314.2,240.1z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M237.7,296.1H113.4c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7h124.2c2.6,0,4.7,2.1,4.7,4.7
          S240.3,296.1,237.7,296.1z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M334.9,352.1H113.4c-2.6,0-4.7-2.1-4.7-4.7s2.1-4.7,4.7-4.7h221.4c2.6,0,4.7,2.1,4.7,4.7
          S337.5,352.1,334.9,352.1z"
          />
        </g>
        <g>
          <path
            class="st1"
            d="M279.5,408.1H113.4c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7h166.1c2.6,0,4.7,2.1,4.7,4.7
          C284.2,406,282.1,408.1,279.5,408.1z"
          />
        </g>
      </g>
    </svg>
    <!-- eslint-enable max-len -->
  </main>
</template>

<script>
export default {
  name: 'EmptyContent',
}
</script>

<style lang="scss" scoped>
#Layer_1 {
  height: 200px;
  width: 100%;
  opacity: 0.6;
  margin: 3em auto;
}

.circle {
  fill: color('grey', 'lighter');
  opacity: 0;
  animation: circle 1s 250ms 1 ease-in-out forwards;
}

.st1 {
  fill: color('grey', 'dark');
}

.paper {
  opacity: 0;
  animation: paper 1s 500ms 1 ease-in-out forwards;
}

@keyframes paper {
  from {
    opacity: 0;
    transform: translate(2em, 1em);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes circle {
  from {
    opacity: 0;
    transform: translate(-2em, -1em);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
</style>
