<template>
  <div>
    <div
      v-lazy-load-bg="mainHero"
      class="spartan-background"
    />

    <TheHeader />

    <SCardContainer id="ContentContainer">
      <slot />
    </SCardContainer>

    <TheFooter />

    <TheTopButton />
  </div>
</template>

<script>
import TheHeader from '~/components/TheHeader'
import TheFooter from '~/components/TheFooter'
import TheTopButton from '~/components/TheTopButton'
import mainHero from '~/assets/images/main_hero.jpg'

export default {
  name: 'BaseLayout',

  components: {
    TheHeader,
    TheFooter,
    TheTopButton,
  },

  data () {
    return {
      mainHero,
    }
  },

  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.spartan-background {
  // Background must be dark to provide contrast for header.
  background: color('blue') right bottom / auto no-repeat;
  content: '';
  height: 380px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;

  @include until-breakpoint('lg') {
    background-size: cover;
    height: 434px;
  }
}

$fade-time: duration('slow');

.page {
  &-enter-active,
  &-leave-active {
    transition: max-height $fade-time easing(), opacity $fade-time easing();
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

#ContentContainer {
  position: relative;
  z-index: 1;
}

@include until-breakpoint('md') {
  ::v-deep .container > .card > .card__body {
    padding: spacing();
  }
}
</style>
