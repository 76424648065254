<template>
  <SContainer>
    <nav
      class="navbar"
      :aria-label="$t('component.navigation.aria.label.primary')"
    >
      <div class="navbar__start">
        <!-- Home -->
        <NuxtLink
          :to="localePath('index')"
          class="navbar__item"
          exact
        >
          <span class="sr-only">
            {{ $t('component.navigation.index') }} {{ siteUrl }}
          </span>

          <SpartanLogo logo-color="white" />
        </NuxtLink>

        <!-- Button must be inside navbar__start -->
        <button
          :aria-expanded="showMenu"
          :class="{ 'navbar__menu-button--active': showMenu }"
          class="navbar__menu-button"
          @click="toggleMenu"
        >
          <!-- Burger -->
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />

          <span class="sr-only">
            {{ $t('component.navigation.menu') }}
          </span>
        </button>
      </div>

      <!-- Navigation -->
      <div
        :class="{ 'navbar__menu--active': showMenu }"
        class="navbar__menu"
      >
        <!-- Links -->
        <!-- hide untranslated routes -->
        <template v-for="(route, key) in navigationLinks">
          <NuxtLink
            v-if="localePath(route.path) !== '/'"
            :key="key"
            :to="localePath(route.path)"
            class="navbar__item"
          >
            {{ $t(route.label) }}
          </NuxtLink>
        </template>

        <div class="navbar__item">
          <!-- remove locale selector from untranslated pages -->
          <LocaleSelector v-if="!$route.path.includes('19')" />
        </div>
      </div>
    </nav>
  </SContainer>
</template>

<script>
import LocaleSelector from '~/components/LocaleSelector'
import SpartanLogo from '~/components/SpartanLogo'
import { navigationLinks } from '~/assets/routes'

export default {
  components: {
    LocaleSelector,
    SpartanLogo,
  },

  data () {
    return {
      showMenu: false,
      isDesktop: false,
      siteUrl: process.env.BASE_URL,
      navigationLinks,
    }
  },

  watch: {
    $route () {
      // Collapse the menu on mobile route change
      this.checkWidth()
    },
  },

  mounted () {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.checkWidth)
      this.checkWidth()
    }
  },

  destroyed () {
    if (typeof window !== 'undefined') window.removeEventListener('resize', this.checkWidth)
  },

  methods: {
    checkWidth () {
      this.showMenu = this.isDesktop = window.innerWidth >= 1088
    },
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: spacing('triple');
  margin-top: spacing('triple');

  @include until-breakpoint('lg') {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__start {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .nuxt-link-active {
      background: none !important;
      box-shadow: none !important;
    }
  }

  &__menu {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    a.navbar__item:not(.nuxt-link-active):hover {
      transition: all duration('fast') easing();

      @include after-breakpoint('lg') {
        box-shadow: inset 0 -2px 0 0 transparentize(color('grey', 'dark'), 0.6);
      }
    }

    @include until-breakpoint('lg') {
      max-height: 0;
      overflow: hidden;
      transition: all duration() easing();

      .navbar__item {
        width: 100%;

        &.nuxt-link-active {
          background-color: transparent;
          transition: background-color duration('fast') easing();
        }
      }

      &--active {
        max-height: 450px;
        margin-bottom: 1em;

        .navbar__item.nuxt-link-active {
          background-color: transparentize(color('white'), 0.8);
        }
      }
    }

    &-button {
      $speed: duration('fast');
      $delay: $speed;
      $easing: easing();

      background: none;
      display: none;
      height: spacing('quadruple');
      padding: spacing();
      position: relative;
      width: spacing('quadruple');

      @include until-breakpoint('lg') {
        display: block;

        span {
          border: none;
          background-color: white;
          display: block;
          height: 1px;
          left: spacing('loose');
          position: absolute;
          // Expand burger
          // Rotate to horizontal line before moving to top and bottom
          transition: top $speed $easing $delay, opacity $speed $easing $delay,
            transform $speed $easing;
          width: spacing();

          &:nth-child(1) {
            top: 1.75em;
          }

          &:nth-child(2) {
            top: 50%;
          }

          &:nth-child(3) {
            top: calc(100% - 1.75em);
          }
        }
      }

      &--active {
        span {
          //  Collapse burger to X
          //  Move top and bottom to middle before rotation
          transition: top $speed $easing, opacity $speed $easing, transform $speed $easing $delay;

          &:nth-child(1) {
            top: 50%;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            top: 50%;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &__item {
    color: white;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    padding: spacing();
    text-decoration: none;
  }

  &__button-group {
    align-items: center;
    display: flex;

    @include until-breakpoint('lg') {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;
    }
  }

  .nuxt-link-active {
    @include after-breakpoint('lg') {
      box-shadow: inset 0 -2px 0 0 color('white');
    }
  }
}
</style>
