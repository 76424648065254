<template>
  <Transition name="fade">
    <SButton
      v-show="showTopButton"
      v-scroll-to="'body'"
      color="night"
      class="button--top"
      icon-only
      icon-left="arrow-up"
    >
      <span class="sr-only">
        {{ $t('component.topButton.topButton') }}
      </span>
    </SButton>
  </Transition>
</template>

<script>
export default {
  data () {
    return {
      showTopButton: false,
    }
  },

  mounted () {
    if (typeof window !== 'undefined') window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  },

  destroyed () {
    if (typeof window !== 'undefined') window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll () {
      this.showTopButton = window.scrollY > 300
    },
  },
}
</script>

<style lang="scss" scoped>
.button--top {
  $distance: spacing();

  bottom: $distance;
  position: fixed;
  right: $distance;
  z-index: 50;
}
</style>
