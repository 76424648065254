import Cookies from 'js-cookie'

export default function ({ app, redirect, route, store }) {
  // Let `nuxt-i18n` handle redirect for first time visitors
  // If `nuxt-i18n` redirected a visitor, redirect them to their locale when they come back
  if (
    Cookies.get('i18n_redirected') &&
    Cookies.get('i18n_locale') &&
    Cookies.get('i18n_locale') !== store.state.i18n.locale
  ) {
    store.dispatch('i18n/setLocale', Cookies.get('i18n_locale'))
    const localePath = app.localePath(app.getRouteBaseName(route), Cookies.get('i18n_locale'))
    return redirect(localePath ? localePath + route.hash : '')
  }
}
