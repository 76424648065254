<template>
  <BaseLayout>
    <SContainerRow>
      <SContainerColumn>
        <Nuxt />
      </SContainerColumn>

      <SContainerColumn
        size="md"
        narrow
      >
        <TheSidebar />
      </SContainerColumn>
    </SContainerRow>
  </BaseLayout>
</template>

<script>
import BaseLayout from '~/components/BaseLayout.vue'
import TheSidebar from '~/components/TheSidebar.vue'

export default {
  // eslint-disable-next-line vue/match-component-file-name
  name: 'SidebarLayout',

  components: {
    BaseLayout,
    TheSidebar,
  },
}
</script>
