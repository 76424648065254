import Vue from 'vue'
export const state = () => ({
  isLoading: false,
  en: {},
  fr: {},
})

export const mutations = {
  SET_CONTENT (state, { lang, contentType, posts = [], total = 0, eTag = '' }) {
    Vue.set(state[lang], contentType, { posts, total, eTag })
  },

  SET_IS_LOADING (state, value) {
    state.isLoading = value
  },
}

export const actions = {
  async fetchContent ({ commit }, { lang, contentType, order }) {
    commit('SET_IS_LOADING', true)

    const content = await this.$ctf.getEntries({
      contentType,
      locale: lang + '-CA',
      order,
    })

    commit('SET_CONTENT', { lang, contentType, ...content })
    commit('SET_IS_LOADING', false)
  },
}
